import React from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ContentRow extends React.Component {
  state = {
    showFeedbackForm: false,
  }
  constructor(props) {
    super(props);
    this.showFeedbackForm = this.showFeedbackForm.bind(this);
  }
  showFeedbackForm() {
    this.setState({
      showFeedbackForm: !this.state.showFeedbackForm,
    });
  }
  render() {
    let className = 'content-row';
    let rowClassName = 'content-row-inner-row';
    let colClassName = 'content-row-inner-col';


    return (
      <Container className={className} fluid>
        <Row className={rowClassName}>
          <Col className={colClassName}>
            {this.props.showFeedback ?
              <Alert className="mt-2 mb-5 feedback-alert" color="danger" onClick={this.showFeedbackForm}>
                <div>
                  {this.props.children}
                </div>
                <Button
                  color="crimson"
                  tag="a"
                  href={`mailto:joseph@lenfestinstitute.org,contact@yellowbrim.com?subject=${
                    encodeURIComponent('newsletterguide.org feedback')
                  }&body=${
                    encodeURIComponent('')}`}
                >
                  <FontAwesomeIcon icon={faComments} className="mr-2" /> Send us feedback
                </Button>
              </Alert>
            : (
              <div ref={this.contentRef}>
                {this.props.children}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
