import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class SectionContent extends React.Component {
  render() {
    let className = 'section-content';
    let rowClassName = 'section-content-inner-row';
    let colClassName = 'section-content-inner-col';
    return (
      <Container className={className} fluid>
        <Row className={rowClassName}>
          <Col className={colClassName}>
            {this.props.children}
          </Col>
        </Row>
      </Container>
    )
  }
}
