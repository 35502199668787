import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SectionHeader from '../components/SectionHeader';
import SectionContent from '../components/SectionContent';
import { parseHTML } from '../parseHTML';
import { Button } from 'reactstrap';
import logo from '../img/newsletterguide-logo-full.svg';
import Logos from '../components/Logos';

const bgs = [
  'connectwork', 'curls', 'dark-triangles',
  'ep-naturalblack', 'spiration-dark',
  'small-steps', 'prism', 'pink-dust'
];

Array.prototype.randomElement = function () {
  return this[Math.floor(Math.random() * this.length)]
}


export default class ContentPage extends React.Component {
  state = {
    showContent: false,
  }
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressPost(sort: { fields: wordpress_id }, limit: 100) {
              edges {
                node {
                  title
                  slug
                  content
                }
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <div className="page-cover">
              <img
                src={logo}
                style={{ width: 300, maxWidth: '100%' }}
                alt="Newsletter Guide.org"
                className="d-block mb-4"
              />
              <h1><span>A 201 guide for taking your newsletters to the next level &mdash; growing the lists, making money, and more.</span></h1>
              <br />
              <p className="lead">
                <a href="/templates">Check out our open source newsletter templates here.</a>
                <br />
                <br />
                <a href="#introduction">Read the guide below</a>
              </p>
            </div>
            <div className="logos">
              <Logos />
            </div>
            {data && data.allWordpressPost && data.allWordpressPost.edges.map(({node}, i) => (
              <div id={node.slug} key={i}>
                <SectionHeader
                  slug={node.slug}
                  name={node.title}
                  bg={"small-steps"}
                />
                <SectionContent>
                  {node.content ? parseHTML(node.content) : ''}
                </SectionContent>
              </div>
            ))}
          </Fragment>
        )}
      />
    );
  }
}

