import React from 'react';
import D3Visual from './components/D3Visual';
import Subscribe from './components/Subscribe';
import ContentRow from './components/ContentRow';

var ReactDOMServer = require('react-dom/server');
var HtmlToReact = require('html-to-react');
var HtmlToReactParser = require('html-to-react').Parser;


export const parseHTML = (htmlInput) => {
  var isValidNode = function () {
    return true;
  };

  // Order matters. Instructions are processed in the order they're defined
  var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  var processingInstructions = [
    {
      // Custom <h1> processing
      shouldProcessNode: function (node) {
        return node.parent && node.parent.name && node.parent.name === 'h1';
      },
      processNode: function (node, children) {
        return node.data.toUpperCase();
      }
    },
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode
    }
  ];

  var htmlToReactParser = new HtmlToReactParser();
  var reactComponent = htmlToReactParser.parseWithInstructions(htmlInput, isValidNode,
    processingInstructions);
  var reactHtml = ReactDOMServer.renderToStaticMarkup(reactComponent);

  var isValidNode = function () {
    return true;
  };

  // Order matters. Instructions are processed in the order they're defined
  var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  var processingInstructions = [
    {
      // remove empty paragraphs
      // replaceChildren: false,
      shouldProcessNode: function (node) {
        if (node.name === 'p' && (!node.children || node.children.length === 0)) {
          return true;
        }
        return false;
        // if (node.parent && node.parent.name && node.parent.name === 'p' && node.parent.textContent.trim() === '') {
        //   return true;
        // }
        // return false;
      },
      processNode: () => {
        return null;
      },
    },
    // embed-youtube
    {
      // process d3 paragraph
      replaceChildren: false,
      shouldProcessNode: function (node) {
        if (!node.children) {
          return false;
        }

        const matchingChild = node.children.find(c => c.data && c.data.includes('[d3_newsletter_spectrum]'));

        return !!matchingChild;
      },
      processNode: function (node, children, i) {
        return <D3Visual key={i} />;
      }
    },
    {
      // remove empty paragraphs
      // replaceChildren: false,
      shouldProcessNode: function (node) {
        if (node.name === 'p' && (
            !node.children || node.children.length === 0
        )) {
          return true;
        }
        return false;
      },
      processNode: () => {
        return null;
      },
    },
    {
      // signup form
      replaceChildren: false,
      shouldProcessNode: function (node) {
        if (node.data && node.data.includes('[signup]')) {
          return true;
        }
        if (!node.children) {
          return false;
        }

        const matchingChild = node.children.find(c =>
          c.data && c.data.includes('[signup]')
        );

        return !!matchingChild;
      },
      processNode: function (node, children, i) {
        return <Subscribe key={i} />;
      }
    },

    // Wrap content in containers
    {
      shouldProcessNode: function (node) {
        return !node.parent && node.children;
      },
      processNode: function (node, children, index) {
        return (
          <ContentRow
            key={index}
            data={children.map(c => c.data)}
            showFeedback={node.attribs && Object.keys(node.attribs).includes('data-feedback')}
          >
            {processNodeDefinitions.processDefaultNode(node, children, index)}
          </ContentRow>
        );
      }
    },
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    }
  ];
  var htmlToReactParser = new HtmlToReactParser();
  var reactComponent = htmlToReactParser.parseWithInstructions(htmlInput, isValidNode,
    processingInstructions);

  return reactComponent;
}
