import React from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import '../all.sass';

const Logos = () => (
  <Row>
    <Col id="shorensteincenter-sidebar-logo" className="sidebar-logo d-flex justify-content-center flex-column">
      <div className="flex-grow-0 flex-shrink-1">
        <a href="https://shorensteincenter.org/?ref=nlg">
          <img
            src="/img/hks-shorenstein.png"
            className="my-2"
            width="100%"
            alt="Harvard Kennedy School Shorenstein Center"
          />
        </a>
      </div>
    </Col>
    <UncontrolledTooltip delay={{ in: 0, out: 0 }} target="shorensteincenter-sidebar-logo">
      Harvard Kennedy School Shorenstein Center
    </UncontrolledTooltip>

    <Col id="lenfestinstitute-sidebar-logo" className="sidebar-logo d-flex justify-content-center flex-column">
      <div className="flex-grow-0 flex-shrink-1">
        <a href="https://www.lenfestinstitute.org/?ref=nlg">
          <img src="/img/lenfest.svg"
            width="100%"
            className="my-2"
            alt="The Lenfest Institute for Journalism" />
        </a>
      </div>
    </Col>
    <UncontrolledTooltip delay={{ in: 0, out: 0 }} target="lenfestinstitute-sidebar-logo">
      The Lenfest Institute for Journalism
    </UncontrolledTooltip>

    <Col id="yellowbrim-sidebar-logo" className="sidebar-logo d-flex justify-content-center flex-column">
      <div className="flex-grow-0 flex-shrink-1">
        <a href="https://yellowbrim.com/?ref=nlg">
          <img src="/img/yellowbrim.svg" alt="Yellow Brim"
            className="my-2"
            width="100%"/>
        </a>
      </div>
    </Col>
    <UncontrolledTooltip delay={{ in: 0, out: 0 }} target="yellowbrim-sidebar-logo">
      Yellow Brim
    </UncontrolledTooltip>
  </Row>
)

export default Logos;
