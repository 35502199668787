import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { parseHTML } from '../parseHTML';


export default class TableOfContents extends React.Component {
  state = {
  }
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressPost(sort: { fields: wordpress_id }, limit: 100) {
              edges {
                node {
                  title
                  slug
                  content
                }
              }
            }
          }
        `}
        render={data => (
          <ul id="table-of-contents" className="sans-serif">
            {data && data.allWordpressPost && data.allWordpressPost.edges.map(({node}, i) => (
              <li key={i} className="mb-1">
                <a href={`#${node.slug}`}>{parseHTML(node.title)}</a>
              </li>
            ))}
          </ul>
        )}
      />
     );
    }
}

