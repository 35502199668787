import React, { Component } from "react";
import { Alert, Input, Button, Row, Col } from 'reactstrap';

import MailchimpSubscribe from "react-mailchimp-subscribe";

export class CustomForm extends Component {
  state = {
    email: '',
    name: '',
  }
  render() {
    const { onValidated, status, message } = this.props;
    const { email, name } = this.state;
    const submit = () =>
      onValidated({
        EMAIL: email,
        NAME: name
      });

    return (
      <Row>
        <Col>
          <Alert
            color="white"
            className="bg-white rounded-0"
          >
            <Row>
              <Col>
                <p className="lead">
                  Please sign up to get occasional email alerts or updates to the Guide.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <Input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col className="flex-grow-0">
                <Button
                  disabled={status === "sending"}
                  color="crimson"
                  onClick={() => submit()}
                >
                  {status !== "sending" ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </Row>

            {status === "success" && (
              <p
                className="font-weight-bold mt-2 mb-0"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "error" && (
              <p
                className="font-weight-bold mt-2 mb-0"
                dangerouslySetInnerHTML={{ __html: message }}
              />
              )}
          </Alert>
        </Col>
      </Row>
    );
  }
};

export default class Subscribe extends Component {
  render() {
    const url =
      "https://yellowbrim.us17.list-manage.com/subscribe/post?u=e02711b31d3df43b6b67b042f&id=c75e5adb22";
    return (
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    );
  }
}
