import ReactGA from 'react-ga';
import Cookies from 'js-cookie';

export const initAnalytics = () => {
  let ngid = Cookies.get('ngid');
  if (!ngid) {
    ngid = `${new Date().getUTCMilliseconds()}-${Math.floor(Math.random() * 1000000000)}`;
    Cookies.set('ngid', ngid);
  }

  ReactGA.initialize('UA-128976371-1', {
    gaOptions: {
      userId: ngid
    }
  });
}