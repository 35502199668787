import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'

export default class ShareButton extends React.Component {
  render () {
    return (
      <Button tag="a" href={`#${this.props.slug}`} className="share-btn mt-2" size="sm" color="link">
        <FontAwesomeIcon icon={faHashtag} />
      </Button>
    );
  }
}
