import React from 'react';
import ReactGA from 'react-ga';
import { Row, Col } from 'reactstrap';
import { faHandRock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentRow from '../components/ContentRow';


export default class D3Visual extends React.Component {

  componentDidMount() {
    const scripts = ["d3.v4.min.js", "d3-3d.min.js", "main.js"];

    scripts.forEach(scriptName => {
      const script = document.createElement("script");
      script.src = `/js/${scriptName}`;
      script.async = false;
      if (!document.querySelector(`script[src="/js/${scriptName}"]`)) {
        document.body.appendChild(script);
      }
    });
  }

  render() {
    return (
      <div className="">
        <ContentRow>
          <div className="p-2">
            <FontAwesomeIcon icon={faHandRock} className="mr-2" /> Drag to change perspective
          </div>
        </ContentRow>
        <Row>
          <Col className="d-flex justify-content-center flex-column">
            <div id="chart-area" className="text-center" onClick={() => {
              ReactGA.event({
                category: 'D3Visual',
                action: 'Click',
              });
            }}></div>
            <div id="newsletter-div">
              <h3 id="newsletter-name"></h3>
              <p id="newsletter-info"></p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
