import React from 'react';
import Helmet from 'react-helmet';
import LogoIcon from './LogoIcon';
import { Container, Row, Col } from 'reactstrap';
import TableOfContents from './TableOfContents';
import moment from 'moment';
import Logos from './Logos';

import '../all.sass';

const SidebarLayout = ({ children }) => (
  <div className="h-100 w-100 overflow-y-auto">
    <Helmet>
      <title>Newsletter Guide.org</title>
      <meta name="keywords" content="Newsletters,Email,Advertising,Sponsorships,Analytics,Editorial,Media" />
      <meta name="author" content="Harvard Kennedy School Shorenstein Center, Yellow Brim, The Lenfest Institute for Journalism" />
      <meta name="developer" content="@charlex" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="A 201 guide for taking your newsletters to the next level—growing the lists, making money, and more." />
      <meta name='og:title' content='Newsletter Guide.org' />
      <meta name='og:type' content='website' />
      <meta name='og:url' content='https://newsletterguide.org/' />
      <meta name='og:image' content='https://newsletterguide.org/img/social-share.png' />
      <meta name='og:site_name' content='Newsletter Guide.org' />
      <meta name='og:description' content='A 201 guide for taking your newsletters to the next level—growing the lists, making money, and more.' />
      {typeof window === `undefined` &&
       (<>
        <meta name='updated' content={moment().format()} />
        <meta name='og:updated_time' content={moment().format()} />
        </>)
      }
    </Helmet>
    <Container fluid className="h-100">
      <Row className="h-100 d-flex">
        <Col id="sidebar" className="overflow-y-auto h-100" md="4" sm="5" lg="3">
          <Row id="sidebar-header">
            <Col className="">
              <LogoIcon />
              <p className="lead text-center font-weight-bold mb-0">newsletter guide.org</p>
            </Col>
          </Row>
          <Row id="sidebar-content" className="px-3 py-4">
            <Col>
              <TableOfContents />
            </Col>
          </Row>
          <Row id="sidebar-footer" className="px-3 py-4">
            {/* <Row>
              <Col>By</Col>
            </Row> */}
            <Logos />
          </Row>
        </Col>
        <Col id="content-well"  className="overflow-y-auto h-100 overflow-y-auto overflow-x-hidden px-0	">
          <div>{children}</div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default SidebarLayout;
